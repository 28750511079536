body {
    @apply relative;
    &.overlay {
        @apply after:opacity-30
        after:bg-black
        after:absolute
        after:w-full
        after:h-full
        after:top-0
        after:left-0
        after:z-[20];
    }
}

.container {
    @apply px-2
    !max-w-[1280px];
}

.section_border {
    @apply border-b
    border-purple;
}

input[type="radio"] {
    @apply w-6
    h-6;
}

input[type="text"],
textarea {
    @apply w-full
        !rounded-md
        !border-gray-300
        !shadow-sm
        focus:border-indigo-300
        focus:ring
        focus:ring-indigo-200
        focus:ring-opacity-5;
}

.mwform-radio-field-text {
    @apply mb-[10px];
}

.footer_cta,
.footer_solutions {
    @apply relative
    bg-cover
    bg-center
    after:absolute
    after:bg-darkBlue
    after:bg-opacity-30
    after:w-full
    after:h-full
    after:top-0
    after:left-0;
    & .container {
        @apply relative
        z-[2];
    }
}

.footer_cta {
    background-image: url("images/footer_cta.jpg");
}

.footer_solutions {
    background-image: url("images/footer_solutions.jpg");
}

.pagination {
    @apply text-center
    block;
}

.page-numbers {
    @apply p-4
    font-medium
    rounded-full;
    &.current {
        @apply text-primary;
    }
    &.prev,
    &.next {
        @apply bg-primary
        text-white
        hover:text-darkBlue
        hover:bg-hoverBlue;
    }
}

.mw_wp_form .error {
    @apply w-full
    ml-4;
}

hr.wp-block-separator {
    @apply h-[1px]
    text-purple
    border-t-0
    opacity-100
    my-8
    w-full;
}

.modal.show {
    @apply !flex
    items-center
    justify-center;
}

#message,
#policy,
#overview,
#access,
#privacy,
#security-policy,
#certification {
    padding-top: 300px;
    margin-top: -300px;
}
