.drawer_body {
    @apply -translate-y-full
    transition-transform
    duration-500
    fixed
    top-0
    right-0
    left-0
    font-medium
    bg-white
    overflow-y-scroll
    h-full
    pt-14
    px-4
    z-20;
    &.show {
        @apply translate-y-0;
    }
}

.drawer_child {
    @apply bg-[#F1F5FC];
}

.drawer_accordion {
    @apply hover:cursor-pointer;
}

.drawer_item {
    @apply h-16
    text-lg
    flex
    items-center
    justify-between
    border-b
    border-[#1A8DFF]
    px-2;
}

.drawer_recruit_nav {
    @media(max-width:340px) {
        padding-bottom:0 !important;
    }
}
