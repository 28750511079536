.content_body table,
table.table_style {
    @apply w-full
    border-b
    border-borderGray;
    & tr td {
        @apply block
        lg:table-cell
        border
        lg:border-b
        border-b-0
        border-borderGray
        align-top
        md:px-8
        md:py-5
        p-4
        first-of-type:lg:w-[365px]
        first-of-type:xl:w-[400px]
        first-of-type:bg-bgBlue;
    }

    & tr td .inner {
        @apply flex
        items-center
        justify-start;
    }

    & tr td span.required {
        @apply text-sm
        text-white
        bg-pink
        p-1
        mr-4;
    }
}

.card_column {
    & ul {
        @apply grid
            md:grid-cols-2;

        & li.box a {
            @apply flex
                flex-col
                justify-center
                md:items-start
                items-center
                p-10
                md:px-8
                md:py-0
                lg:py-0
                lg:px-11
                xl:px-14
                2xl:px-20;
        }
        & li.box {
            @apply order-2;
        }
        & li.img {
            @apply order-1;
        }
    }

    & ul.reverse {
        & li.box {
            @apply md:order-1
                    order-2;
        }
        & li.img {
            @apply md:order-2
                    order-1;
        }
    }
}

ul.column_border {
    @apply grid
    grid-cols-1
    xl:gap-[70px]
    lg:gap-[50px]
    md:gap-9
    gap-9
    mb-12;
    & li:first-child {
        @apply md:border-r-2
        md:border-0
        border-b-2
        border-darkBlue
        pb-2;
    }
}

.questions {
    @apply lg:mx-4;
    & .wrapper {
        @apply bg-textGray
        py-5
        lg:py-10
        mx-auto
        md:mb-8
        mb-4
        max-w-[1760px];
    }
    & .container {
        @apply lg:px-32;
    }
    & .heading_wrap {
        @apply text-center 
        mb-8 
        lg:mb-16;
        & img {
            @apply mb-5 
            lg:mb-6;
        }
    }
    & .column {
        @apply grid 
         
        gap-x-3 
        gap-y-8 
        lg:gap-y-14 
        lg:gap-x-10;
        &.four {
            @apply grid-cols-2;
        }
        &.three {
            @apply md:grid-cols-3
            grid-cols-1
            md:mb-16;
        }
    }

    & .item {
        @apply relative 
        flex 
        items-center 
        justify-center 
        lg:text-xl
        md:text-lg
        font-normal
        md:font-medium
        text-center 
        w-full 
        bg-bgGray
        rounded-md 
        lg:px-5
        px-2
        py-[15px] 
        lg:py-[30px];
    }

    & .colon_mark {
        @apply absolute 
        -top-8
        md:top-0
        lg:top-4 
        left-1/2 
        -translate-x-1/2 
        text-[50px] 
        lg:text-[100px] 
        text-borderGray;
    }

    & .content {
        @apply lg:leading-normal
        lg:text-xl;
    }
}
.recommends {
    @apply relative
    mt-4
    lg:mx-4;
    & .arrow {
        @apply absolute 
        w-12
        md:w-16
        lg:w-auto
        md:-top-12 
        -top-8
        left-1/2 
        -translate-x-1/2;
    }
    & .wrapper {
        @apply bg-lightPink
        py-5
        lg:py-10
        mx-auto
        max-w-[1760px];
    }
    & .container {
        @apply lg:px-32;
    }
    & .heading_wrap {
        @apply relative
        mb-4
        lg:mb-11
        before:hidden
        md:before:block
        before:w-[60px]
        before:h-[2px]
        before:bg-primary
        before:rotate-45
        before:absolute
        before:left-0
        lg:before:-left-8
        xl:before:left-0
        before:top-1/2
        before:-translate-y-1/2
        after:hidden
        md:after:block
        after:w-[60px]
        after:h-[2px]
        after:bg-primary
        after:-rotate-45
        after:absolute
        after:right-0
        lg:after:-right-8
        xl:after:right-0
        after:top-1/2
        after:-translate-y-1/2;
    }

    & .column {
        @apply grid 
        
        gap-x-3 
        gap-y-4 
        lg:gap-y-7 
        lg:gap-x-10;
        &.two {
            @apply md:grid-cols-2 
            grid-cols-1;
        }

        &.one {
            @apply grid-cols-1;
        }
    }

    & .item {
        @apply relative 
        flex 
        font-normal
        lg:text-xl
        md:text-lg
        md:font-medium
        items-center 
        justify-center 
        text-center 
        w-full 
        bg-white 
        rounded-md 
        px-4
        py-[12px] 
        lg:py-[18px];
    }
}

.flow {
    @apply lg:mx-4;
    & .wrapper {
        @apply bg-bgBlue
        py-10 
        lg:py-20
        mx-auto
        max-w-[1760px];
    }
    & .column {
        @apply grid 
        md:grid-cols-2 
        grid-cols-1
        gap-y-8
        gap-x-4
        md:gap-x-8
        lg:gap-y-10 
        xl:gap-x-20;
    }

    & .label {
        @apply bg-white
        flex
        flex-col
        items-center
        justify-center
        font-medium
        w-full
        py-4
        mb-7;
        & .step {
            @apply text-primary
            text-xs
            md:text-base
            mb-1;
        }
        & .name {
            @apply text-xl;
        }
    }
}
