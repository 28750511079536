.swiper-button-prev,
.swiper-button-next {
    @apply !z-[9];
}

.home {
    & .swiper-button-prev,
    & .swiper-button-next {
        @apply !text-primary
        py-4
        px-[1.4rem]
        bg-white
        hover:bg-hoverBlue
        rounded-full
        top-[22%];
        &:hover:after {
            @apply text-white;
        }
        &:after {
            @apply !text-base;
        }
    }

    & .swiper-button-prev {
        @apply -left-[25px];
    }

    & .swiper-button-next {
        @apply -right-[25px];
    }
}

.swiper_heroProduct_thumbnail {
    & .swiper-slide {
        @apply aspect-[10/3]
        opacity-40
        hover:cursor-pointer;
        &.swiper-slide-thumb-active {
            @apply !opacity-100;
        }
    }

    & .swiper-slide img {
        @apply w-full
        h-full
        object-cover
        object-center;
    }
}

.swiper_heroProduct {
    & .swiper-slide {
        @apply md:aspect-[16/5]
        w-full;
    }
    & .swiper-slide img {
        @apply w-full
        h-full
        object-cover
        object-center;
    }
}

.hero_product_wrapper {
    & .swiper-button-prev,
    & .swiper-button-next {
        @apply !text-primary
        py-4
        px-[1.4rem]
        bg-white
        hover:bg-hoverBlue
        rounded-full
        top-1/2
        z-[10];
        &:hover:after {
            @apply text-white;
        }
        &:after {
            @apply !text-base;
        }
    }

    & .swiper-button-prev {
        @apply 2xl:-left-14
        xl:-left-0
        -left-2;
    }

    & .swiper-button-next {
        @apply 2xl:-right-14
        xl:-right-0
        -right-2;
    }
}

.swiper_recruit_staff {
    @apply relative;
    & .swiper-button-prev,
    & .swiper-button-next {
        @apply !text-primary
        py-4
        px-[1.4rem]
        bg-bgGray
        hover:bg-hoverBlue
        top-1/2
        z-[10];
        &:hover:after {
            @apply text-white;
        }
        &:after {
            @apply !text-base;
        }
    }

    & .swiper-button-prev {
        @apply left-0
        p-1
        md:p-2
        lg:p-4;
    }
    & .swiper-button-next {
        @apply right-1
        p-1
        md:p-2
        lg:p-4;
    }
}
