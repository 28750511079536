.p-bg-black {
    @apply text-white
    bg-black;
}

.e_bg_full {
    margin: 0 calc(50% - 50vw);
    padding: 1rem calc(50vw - 50%);
    max-width: 100vw;
}

.e_bg_full.blue {
    background: #f1f5fc;
}

.e_bg_full.red {
    background: #f9ece0;
}

.e_bg_full.yellow {
    background: #fbfcf1;
}

.e_lh_short {
    line-height: 1.3rem !important;
}

.e_lh_high {
    line-height: 3rem !important;
}

.e_heading_large {
    @apply lg:text-3xl
    text-2xl
    text-darkBlue
    font-medium
    lg:leading-relaxed
    lg:py-[60px]
    py-[30px]
    lg:mb-[32px]
    mb-[16px]
    border-b
    border-purple;
}

.e_heading_middle {
    @apply lg:text-2xl
    text-xl
    text-darkBlue
    font-medium
    lg:leading-relaxed
    lg:py-[60px]
    py-[30px];
}

.e_heading_small {
    @apply lg:text-xl
    text-lg
    font-medium
    text-darkBlue
    lg:py-[60px]
    py-[30px]
    flex
    items-center;
}

.e_heading_small:after,
.e_heading_small:before {
    @apply content-[""]
    h-[2px]
    bg-darkBlue
    flex-grow;
}

.e_heading_small:after {
    @apply ml-2;
}

.e_heading_small:before {
    @apply mr-2;
}
