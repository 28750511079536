.home {
    & .product {
        background: #f1f5fc;
        @media (min-width: 1830px) {
            background: linear-gradient(
                to left,
                #f1f5fc,
                #f1f5fc 95vw,
                #fff 95vw,
                #fff
            );
        }
    }
    & .info {
        margin-bottom: 2.5rem;
        @media (min-width: 1830px) {
            margin-top: 5rem;
            margin-bottom: 5rem;
        }
    }
}

.info {
    @apply bg-bgYellow;
    & .container {
        @apply py-10 
        lg:py-20;
    }
}

.staff_interview {
    & p {
        @apply text-sm
        lg:text-base;
    }
}

.content_body {
    & ul {
    }
    & ul li {
        @apply flex
        items-start
        before:content-["・"]
        before:text-pink
        before:text-[2rem]
        before:leading-none;
    }
}

.pink_dot {
    @apply flex
        items-start
        justify-end
        before:content-["・"]
        before:text-pink
        before:text-[2rem]
        before:leading-none;
}

.communication .image_wrapper img {
    @apply object-cover
    w-full
    h-full;
}

.form_body a {
    color: #0d6efd;
    text-decoration: underline;
}
