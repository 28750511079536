.hero_normal {
    & img {
        @apply w-full 
        h-[150px]
        md:h-56 
        lg:h-64 
        xl:h-80 
        object-cover 
        object-center;
    }
    & .inner {
        @apply absolute
        left-0
        right-0
        bottom-0 
        w-full
        font-medium
        flex
        flex-col
        justify-end
        items-start
        xl:py-8
        md:py-6
        py-3
        bg-opacity-70;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 0.7) 50%,
            transparent 50%,
            transparent 1000%
        );
        @media (max-width: 560px) {
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0.7) 85%,
                transparent 85%,
                transparent 1000%
            );
        }
    }

    & .hero_title {
        @apply font-medium
        text-2xl
        md:text-3xl
        lg:text-4xl
        xl:text-[40px]
        xl:leading-none;
    }

    & .hero_subTitle {
        @apply font-medium
        text-xs
        md:text-sm
        lg:text-base
        mb-2;
    }
}

.hero_reduce {
    @apply hidden 
    xl:block 
    fixed 
    top-0 
    left-0 
    right-0 
    bg-white 
    transition-transform 
    shadow 
    z-10;
    & .outer {
        @apply relative 
        h-[100px] 
        after:bg-white
        after:bg-opacity-70
        after:absolute
        after:w-full
        after:h-full
        after:top-0
        after:left-0;
    }
    & .outer img {
        @apply w-full
        h-full
        object-cover 
        object-center;
    }

    & .inner {
        @apply absolute 
        w-full 
        h-full 
        left-0 
        top-0 
        flex
        items-center
        z-[2];
    }

    & .hero_subTitle {
        @apply font-medium
        text-sm
        mr-4;
    }

    & .hero_title {
        @apply text-3xl
        font-medium;
    }
}
