@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css";

.button_primary {
    @apply bg-primary
    hover:bg-hoverBlue
    text-white
    hover:text-text
    transition-colors
    flex
    items-center
    justify-center;
    & a {
        @apply xl:py-4
        xl:px-[70px];
    }
}

.button_secondary {
    @apply relative
    bg-primary
    hover:bg-hoverBlue
    text-white
    hover:text-text
    transition-colors
    flex
    items-center
    justify-center
    rounded-full
    hover:after:text-white
    hover:opacity-100;
    & a {
        @apply py-2
        px-9
        hover:opacity-100;
    }
    &:after {
        @apply absolute
        right-3
        text-white
        text-sm;
        font-family: "Font Awesome 5 Free";
        content: "\f054" !important;
        font-weight: 900;
    }
}

.button_cta {
    @apply relative
    bg-white
    hover:bg-hoverBlue
    text-lightBlue
    hover:text-darkBlue
    transition-colors
    flex
    items-center
    justify-center
    rounded-full
    font-medium
    lg:text-2xl
    hover:after:text-white;
    & a {
        @apply py-3
        px-16
        lg:py-5
        lg:px-32
        hover:opacity-100;
    }
    &:after {
        @apply absolute
        right-5
        text-lg;
        font-family: "Font Awesome 5 Free";
        content: "\f054" !important;
        font-weight: 900;
    }
}

.button_entry {
    @apply relative
     border-pink
    border-2
    bg-pink
    text-white
    lg:text-lg
    text-sm
    max-w-full
    mb-4
    lg:mb-8
    hover:bg-buttonPink
    hover:border-buttonPink;
    &:hover {
        & .inner {
            @apply text-buttonPink;
        }
    }
    & a {
        @apply hover:opacity-100
        py-4
        pr-8
        flex
        justify-center
        w-full;
    }

    & .inner {
        @apply absolute 
        right-0
        top-0
        flex
        items-center
        justify-center
        bg-white
        h-full
        w-[60px]
        font-bold
        text-pink;
    }
}

.button_entry.fix {
    @apply w-[410px];
}

.button_entry.flux {
    @apply w-full
    md:w-fit;
    & a {
        @apply md:px-24
        md:pr-32
        px-12
        pr-20;
    }
}
