@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

.heading_primary {
    @apply flex
    flex-col-reverse
    font-medium;
    & h2 {
        @apply text-3xl
        lg:text-[40px]
        lg:leading-none;
    }

    & p {
        @apply text-sm
        text-primary
        lg:text-base
        mb-2;
    }
}

.heading_secondary {
    @apply lg:text-3xl
    text-2xl
    text-darkBlue
    font-medium
    lg:leading-relaxed
    lg:py-[60px]
    py-[30px];
}

.heading_border {
    @apply border
    border-pink
    bg-white
    py-8
    px-2
    leading-9
    lg:leading-loose
    text-pink
    text-center
    text-lg
    lg:text-xl
    font-medium;
}

.heading_line {
    @apply relative
    font-medium 
    text-center 
    text-darkBlue 
    lg:text-xl 
    text-lg
    mb-8 
    lg:mb-10
    after:bg-darkBlue
    after:h-[2px]
    after:w-full
    after:block
    after:absolute
    after:top-1/2
    after:-translate-y-1/2;
    & span {
        @apply relative
        z-[2]
        px-4
        bg-white;
    }
}

.heading_subTitle {
    @apply font-medium 
    text-center 
    text-darkBlue 
    text-xl 
    lg:text-2xl 
    mb-8 
    lg:mb-10;
}

.heading_label {
    @apply bg-bgBlue
    text-darkBlue
    text-center
    py-5
    font-medium
    lg:text-xl
    md:text-lg
    text-base;
}

ul.list_items {
    & li {
        @apply relative
        pl-6
        before:content-['●']
        before:text-xs
        before:absolute
        before:left-0
        before:top-2
        /* md:before:top-1/2 */
        /* md:before:-translate-y-1/2; */;
    }
}



.icon_q {
    @apply text-darkBlue
    font-medium
    md:leading-none
    md:mr-8
    mr-4
    md:text-3xl
    text-2xl;
}

.icon_a {
    @apply text-pink
    font-medium
    md:leading-none
    md:mr-8
    mr-4
    md:text-3xl
    text-2xl;
}
