@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-base
    leading-[1.8]
    tracking-wider
    text-base
    text-text
    overflow-x-hidden
    scroll-smooth;
}

* {
    @apply max-w-full;
    &:active {
        @apply outline-none
        shadow-none;
    }
    &:focus {
        @apply outline-none
        shadow-none;
    }
}

a {
    @apply text-inherit
    hover:no-underline
    hover:opacity-70
    hover:text-inherit;
}

img {
    @apply max-w-full
    max-h-full
    h-auto
    inline;
}

*::-webkit-scrollbar {
    @apply w-[3px]
    h-[3px];
}

*::-webkit-scrollbar-track {
    @apply bg-[darkgrey];
}

*::-webkit-scrollbar-thumb {
    @apply bg-primary;
}
